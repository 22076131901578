@use 'module' as mdl;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: bottom;
	text-decoration: none;
	box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
img {
  vertical-align: bottom;
  max-width: 100%;
  width: auto;
  height: auto;
}

input[type="submit"],
input[type="button"],
button {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
    &::-webkit-search-decoration {
      display: none;
    }
    &::focus {
      outline-offset: -2px;
    }
}

.iframe-wrap{
  position:relative;
  width:100%;
  height:0;
  padding-bottom:56.25%;
	iframe{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
}

@include mdl.tab {
  .pc {
    display: none !important;
  }
}
@include mdl.pc {
  .sp {
    display: none !important;
  }
}

html{
	font-size: 62.5%;
}

body{
	font-family: mdl.$noto;
	font-size: 1.4rem;
  color: mdl.$bl;
  display: flex;
  flex-direction:column;
  min-height:100vh;
  &.fixed{
    overflow: hidden;
  }
}

a{
  color: mdl.$bl;
  img{
    transition: opacity .3s ease;
  }
  &:hover{
    img{
      opacity: 0.7;
    }
  }
}

.bold{
  font-weight: bold;
}

.mont{
  font-family: mdl.$mont;
}

.noto{
  font-family: mdl.$noto;
}

.rd{
  color: mdl.$rd;
}

.boxed{
  border: solid 1px #D8D8D8;
  padding: 18px 3%;
  @include mdl.tab{
    padding: 18px 6%;
  }
}

.list-indent{
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  line-height: 2;
  padding-left: 30px;
  &__item{
    padding-left: 1.5rem;
    text-indent: -1.5rem;
  }
  @include mdl.tab{
    font-size: 1.3rem;
    line-height: calc(25 / 13);
    padding-left: 20px;
  }
}

/*　ボタン　ここから　*/

.underline{
  text-decoration:underline;
  &:hover{
    text-decoration:none;
  }
}

.btn-arrow{
  max-width:100%;
  display: inline-block;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  font-family: mdl.$mont;
  font-weight: mdl.$s-bold;
  color: #fff;
  background: mdl.$rd;
  border-radius:100px;
  line-height: calc(22 / 16);
  padding: 19px 50px 19px 40px;
  position: relative;
  cursor: pointer;
  transition:.3s ease;
  box-shadow: 3px 3px 0 rgba(0,0,0,.16);
  text-align: left;
  .arrow{
    width: 26px;
    height: 26px;
    border-radius:50%;
    background: #fff;
    position: absolute;
    right:15px;
    top: 50%;
    transform: translateY(-50%);
    transition:.3s ease;
    &:after{
      content:"";
      display: block;
      position: absolute;
      right:11px;
      top:50%;
      width: 6px;
      height: 6px;
      border-top: solid 2px mdl.$bl;
      border-right: solid 2px mdl.$bl;
      transform: translateY(-50%) rotate(45deg);
      transition:.3s ease;
    }
  }
  &:hover{
    background: mdl.$sc;
    padding-left: 30px;
    .arrow{
      right:10px;
    }
  }
  @include mdl.tab{
    padding: 19px 50px 19px 30px;
    &:hover{
      padding-left: 20px;
    }
  }
  &.down{
    .arrow{
      &:after{
        right:9px;
        transform: translateY(-50%) rotate(135deg);
      }
    }
  }
}

.btn-anchor{
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  line-height: calc(20 / 14);
  display: inline-block;
  text-align: left;
  padding-left: 20px;
  position: relative;
  border-bottom: solid 1px transparent;
  .arrow{
    width: 14px;
    height: 14px;
    border-radius:50%;
    background: mdl.$rd;
    border: solid 1px mdl.$rd;
    position: absolute;
    left:0;
    top: 50%;
    transform: translateY(-50%);
    transition:.3s ease;
    &:after{
      content:"";
      display: block;
      position: absolute;
      left:3px;
      top:50%;
      width: 4px;
      height: 4px;
      border-bottom: solid 2px #fff;
      border-right: solid 2px #fff;
      transform: translateY(-50%) rotate(45deg);
      transition:.3s ease;
    }
  }
  &--blank{
    &:after{
      content:url(../img/common/ic_blank_bl.svg);
      display: inline-block;
      padding-left: 5px;
    }
  }
  &:hover{
    .arrow{
      background: #fff;
      &:after{
        border-color: mdl.$rd;
      }
    }
  }
  @include mdl.tab{
    font-size: 1.3rem;
    line-height: calc(19 / 13);
  }
}

/*　ボタン　ここまで　*/


/*　見出し　ここから　*/

.ttl-img{
  font-size: 3rem;
  font-weight: bold;
  font-family: mdl.$mont;
  letter-spacing: 0.03em;
  text-align: center;
  background: url(../img/common/ttl_img.svg) center top no-repeat;
  padding: 50px 0 20px;
  position: relative;
  &:after{
    content:"";
    display: block;
    position: absolute;
    bottom:0;
    left:calc(50% - 15px);
    width: 30px;
    height: 2px;
    background: #D8D8D8;
  }
  @include mdl.tab{
    font-size: 2.4rem;
    line-height: calc(36 / 24);
    padding: 47px 0 12px;
  }
}

.under-ttl{
  width: 1080px;
  max-width: 90%;
  margin: 0 auto;
  .en{
    font-family: mdl.$mont;
    font-size: 6rem;
    font-weight: bold;
    letter-spacing: 0.03em;
  }
  .jp{
    font-size: 1.3rem;
    letter-spacing: 0.1em;
    display: block;
    margin-top: 10px;
  }
  @include mdl.tab{
    .en{
      font-size: 4.5rem;
    }
  }
  @include mdl.sp{
    width: 315px;
  }
}

.ttl-border{
  color: #fff;
  font-size: 2.4rem;
  letter-spacing: 0.1em;
  font-weight: mdl.$medium;
  line-height: calc(36 / 24);
  background: mdl.$bl;
  margin-bottom: 15px;
  border-left: 10px solid mdl.$rd;
  padding: 7px 10px 7px 15px;
  @include mdl.tab{
    font-size: 1.8rem;
  }
}

/*　見出し　ここまで　*/

.news,
.schedule{
  &__list{
    display: flex;
    flex-wrap:wrap;
  }
  &__link{
    display: block;
    &:hover{
      text-decoration:underline;
      .img-wrap{
        opacity: 0.7;
      }
    }
  }
  &__item{
    @include mdl.pc{
      width: calc((100% - 6px) / 3);
      margin-right: 3px;
      &:nth-of-type(3n){
        margin-right: 0;
      }
      &:nth-of-type(n+4){
        margin-top: 39px;
      }
    }
    @include mdl.tab{
      width: calc((100% - 1px) / 2);
      margin-right: 1px;
      &:nth-of-type(even){
        margin-right: 0;
      }
      &:nth-of-type(n+3){
        margin-top: 42px;
      }
    }
    .img-wrap{
      background: center center /cover no-repeat;
      padding-bottom: 57%;
      transition:.3s ease;
      margin-bottom: 16px;
      @include mdl.tab{
        padding-bottom: 100%;
        margin-bottom: 8px;
      }
    }
    .txt-wrap{
      color: mdl.$bl;
      .ttl{
        font-size: 1.4rem;
        letter-spacing: 0.1em;
        line-height: calc(22 / 14);
      }
      @include mdl.tab{
        padding: 0 7%;
        .ttl{
          font-size: 1.2rem;
          line-height: calc(18 / 12);
        }
      }
    }
  }
  .date{
    font-family: mdl.$mont;
    font-size: 1.4rem;
    letter-spacing: 0.1em;
    margin-bottom: 10px;
  }
  .cat-row{
    margin: 0 -5px 6px;
  }
  .cat{
    display: inline-block;
    margin: 0 5px 5px;
    &__link{
      font-size: 1.2rem;
      letter-spacing: 0.1em;
      color: mdl.$bl;
      font-weight: mdl.$medium;
      border: solid 1px mdl.$bl;
      padding: 4px 7px;
      display: block;
      transition:.3s ease;
      &:hover{
        background: mdl.$bl;
        color: #fff;
      }
    }
    &__txt{
      font-size: 1.2rem;
      letter-spacing: 0.1em;
      color: mdl.$bl;
      font-weight: mdl.$medium;
      border: solid 1px mdl.$bl;
      padding: 4px 7px;
      display: block;
      transition:.3s ease;
    }
    &--event{
      .cat__txt{
        &:after{
          display: block;
        }
        &.theater{
          &:after{
            content: mdl.$cat1;
          }
        }
        &.kamenjoshi_gaibu{
          &:after{
            content: mdl.$cat2;
          }
        }
        &.steamgirls_gaibu{
          &:after{
            content: mdl.$cat3;
          }
        }
        &.photo_session{
          &:after{
            content: mdl.$cat4;
          }
        }
        &.media{
          &:after{
            content: mdl.$cat5;
          }
        }
        &.other{
          &:after{
            content: mdl.$cat6;
          }
        }
      }
    }
  }
  @include mdl.tab{
    .date{
      margin-bottom: 5px;
    }
    .cat-row{
      margin-bottom: 0;
    }
    .cat{
      font-size: 1rem;
    }
  }
}

.pagination{
  width: 960px;
  max-width: 90%;
  margin: 102px auto 0;
  @include mdl.tab{
    margin-top: 63px;
  }
  @include mdl.sp{
    width: 315px;
  }
  ul{
    display: flex;
    justify-content: center;
    position: relative;
    padding: 0 35px;
    .page-numbers{
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius:50%;
      font-family: mdl.$mont;
      font-size: 1.6rem;
      font-weight: mdl.$medium;
      color: mdl.$bl;
      transition:.3s ease;
      width: 32px;
      height: 32px;
      &:not(.prev,.next){
        margin: 0 9px;
        &.current,
        &:hover{
          position: relative;
          &:after{
            content:"";
            display: block;
            position: absolute;
            bottom:-2px;
            width: 20px;
            height: 2px;
            background: mdl.$bl;
          }
        }
      }
      @include mdl.tab{
        width: 30px;
        height: 30px;
        &:not(.prev,.next){
          margin: 0 5px;
        }
      }
      &.prev,
      &.next{
        border: solid 1px #D8D8D8;
        position: absolute;
        &:after{
          content:"";
          display: block;
          position: absolute;
          width: 10px;
          height: 10px;
          border-top: solid 2px mdl.$bl;
          border-right: solid 2px mdl.$bl;
        }
        &:hover{
          background: #D8D8D8;
          &:after{
            border-color:#fff;
          }
        }
      }
      &.prev{
        left:calc(50% - 190px);
        &:after{
          transform:rotate(-135deg);
          left: calc(50% - 3px);
        }
        @include mdl.tab{
          left:0;
        }
      }
      &.next{
        right:calc(50% - 190px);
        &:after{
          transform:rotate(45deg);
          right: calc(50% - 3px);
        }
        @include mdl.tab{
          right:0;
        }
      }
    }
  }
}


.header{
  background: mdl.$bl;
  text-align: center;
  @include mdl.pc{
    padding: 60px 0 5px;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    width: 15%;
    max-width:240px;
    min-width:170px;
    height: 100vh;
    overflow: auto;
  }
  @include mdl.tab{
    padding: 17px calc(5% + 50px) 16px 5%;
  }
  &__logo-wrap{
    margin-bottom: 30px;
    @include mdl.tab{
      margin: 0;
    }
  }
  &__nav{
    display: inline-block;
    width: auto;
    min-width:110px;
    text-align: left;
    .menu{
      &__item{
        &:nth-of-type(n+2){
          margin-top: 22px;
        }
      }
      &__link{
        font-size: 1.3rem;
        color: #fff;
        font-family: mdl.$mont;
        font-weight: mdl.$medium;
        letter-spacing: 0.03em;
        transition:.3s ease;
        padding-bottom: 7px;
        border-bottom: 1px solid transparent;
        &:hover{
          border-color: #fff;
        }
        &--blank{
          &:after{
            content:url(../img/common/ic_blank.svg);
            display: inline-block;
            padding-left: 5px;
          }
        }
      }
    }
    @include mdl.tab{
      display: none;
      position: fixed;
      top:0;
      bottom:0;
      left:0;
      right:0;
      z-index: 999;
      height: 100vh;
      background: mdl.$bl;
      padding: 17px 5% 40px;
      overflow: auto;
      .logo-wrap{
        text-align: center;
        margin-bottom: 31px;
        padding-right: 50px;
      }
      .menu{
        margin: 0 auto;
        width:214px;
        max-width:100%;
        &__item{
          &:nth-of-type(n+2){
            margin-top: 20px;
          }
        }
        &__link{
          padding: 0;
          border:none;
        }
      }
    }
  }
  .menu-trigger{
    position: fixed;
    display: block;
    right:5%;
    top:16px;
    z-index: 999;
    width: 46px;
    height: 46px;
    border-radius:50%;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
    span{
      display: inline-block;
      height: 3px;
      background: mdl.$bl;
      position: absolute;
      left:10px;
      &:nth-of-type(1){
        width: 26px;
        top:30%;
      }
      &:nth-of-type(2){
        width: 20px;
        top:50%;
      }
      &:nth-of-type(3){
        width: 14px;
        top:70%;
      }
    }
    &.active{
      width: 40px;
      height: 40px;
      background: none;
      border-radius: 0;
      box-shadow:none;
      span{
        background: #fff;
        width: 100%;
        height: 3px;
        left:50%;
        &:nth-of-type(1){
          top:50%;
          transform:translateX(-50%) rotate(36deg);
        }
        &:nth-of-type(2){
          opacity: 0;
        }
        &:nth-of-type(3){
          top:50%;
          transform:translateX(-50%) rotate(-36deg);
        }
      }
    }
  }
}

.main{
  @include mdl.pc{
    margin-top: 93px;
    display: block;
    width: 85%;
    max-width: calc(100% - 170px);
    min-width: calc(100% - 240px);
    margin-left: auto;
  }
  @include mdl.tab{
    margin-top: 46px;
  }
}

.footer{
  margin-top: auto;
  background: #2A2A2A;
  padding: 39px 0 32px;
  position: relative;
  @include mdl.pc{
    width: 85%;
    max-width: calc(100% - 170px);
    min-width: calc(100% - 240px);
    margin-left: auto;
  }
  @include mdl.tab{
    padding: 41px 5% 42px;
  }
  &__logo-wrap{
    text-align: center;
  }
  &__nav{
    margin: 42px 0 35px;
    .menu{
      display: flex;
      justify-content: center;
      flex-wrap:wrap;
      &__item{
        margin: 0 9px 5px;
      }
      &__link{
        color: #fff;
        font-family: mdl.$mont;
        font-size: 1.3rem;
        font-weight: mdl.$medium;
        padding-bottom: 5px;
        border-bottom: 1px solid transparent;
        transition:.3s ease;
        &:hover{
          border-color:  #fff;
        }
        &--blank{
          &:after{
            content:url(../img/common/ic_blank.svg);
            display: inline-block;
            padding-left: 5px;
          }
        }
      }
    }
    @include mdl.tab{
      max-width:100%;
      width: 315px;
      margin: 40px auto 30px;
      .menu{
        &__item{
          margin: 0 6px 12px;
        }
      }
    }
  }
  .sns{
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    &__link{
      margin: 0 8px;
    }
  }
  &__nav2{
    margin-bottom: 83px;
    .menu{
      display: flex;
      justify-content: center;
      &__item{
        padding: 0 20px 2px;
        &:nth-of-type(n+2){
          border-left: solid 1px #fff;
        }
      }
      &__link{
        color: #fff;
        font-size: 1.1rem;
        letter-spacing: 0.05em;
      }
    }
  }
  .copyright{
    color: #fff;
    font-size: 1rem;
    letter-spacing: .1em;
    text-align: center;
  }
}