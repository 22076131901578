@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: bottom;
  text-decoration: none;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  vertical-align: bottom;
  max-width: 100%;
  width: auto;
  height: auto;
}

input[type=submit],
input[type=button],
button {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
}
input[type=submit]::-webkit-search-decoration,
input[type=button]::-webkit-search-decoration,
button::-webkit-search-decoration {
  display: none;
}
input[type=submit]::focus,
input[type=button]::focus,
button::focus {
  outline-offset: -2px;
}

.iframe-wrap {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.iframe-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .pc {
    display: none !important;
  }
}
@media (min-width: 1025px) {
  .sp {
    display: none !important;
  }
}
html {
  font-size: 62.5%;
}

body {
  font-family: "Noto Sans JP", serif;
  font-size: 1.4rem;
  color: #101010;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body.fixed {
  overflow: hidden;
}

a {
  color: #101010;
}
a img {
  transition: opacity 0.3s ease;
}
a:hover img {
  opacity: 0.7;
}

.bold {
  font-weight: bold;
}

.mont {
  font-family: "Montserrat", "Noto Sans JP", sans-serif;
}

.noto {
  font-family: "Noto Sans JP", serif;
}

.rd {
  color: #B40707;
}

.boxed {
  border: solid 1px #D8D8D8;
  padding: 18px 3%;
}
@media (max-width: 1024px) {
  .boxed {
    padding: 18px 6%;
  }
}

.list-indent {
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  line-height: 2;
  padding-left: 30px;
}
.list-indent__item {
  padding-left: 1.5rem;
  text-indent: -1.5rem;
}
@media (max-width: 1024px) {
  .list-indent {
    font-size: 1.3rem;
    line-height: 1.9230769231;
    padding-left: 20px;
  }
}

/*　ボタン　ここから　*/
.underline {
  text-decoration: underline;
}
.underline:hover {
  text-decoration: none;
}

.btn-arrow {
  max-width: 100%;
  display: inline-block;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  font-family: "Montserrat", "Noto Sans JP", sans-serif;
  font-weight: 600;
  color: #fff;
  background: #B40707;
  border-radius: 100px;
  line-height: 1.375;
  padding: 19px 50px 19px 40px;
  position: relative;
  cursor: pointer;
  transition: 0.3s ease;
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.16);
  text-align: left;
}
.btn-arrow .arrow {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s ease;
}
.btn-arrow .arrow:after {
  content: "";
  display: block;
  position: absolute;
  right: 11px;
  top: 50%;
  width: 6px;
  height: 6px;
  border-top: solid 2px #101010;
  border-right: solid 2px #101010;
  transform: translateY(-50%) rotate(45deg);
  transition: 0.3s ease;
}
.btn-arrow:hover {
  background: #E10000;
  padding-left: 30px;
}
.btn-arrow:hover .arrow {
  right: 10px;
}
@media (max-width: 1024px) {
  .btn-arrow {
    padding: 19px 50px 19px 30px;
  }
  .btn-arrow:hover {
    padding-left: 20px;
  }
}
.btn-arrow.down .arrow:after {
  right: 9px;
  transform: translateY(-50%) rotate(135deg);
}

.btn-anchor {
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  line-height: 1.4285714286;
  display: inline-block;
  text-align: left;
  padding-left: 20px;
  position: relative;
  border-bottom: solid 1px transparent;
}
.btn-anchor .arrow {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #B40707;
  border: solid 1px #B40707;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s ease;
}
.btn-anchor .arrow:after {
  content: "";
  display: block;
  position: absolute;
  left: 3px;
  top: 50%;
  width: 4px;
  height: 4px;
  border-bottom: solid 2px #fff;
  border-right: solid 2px #fff;
  transform: translateY(-50%) rotate(45deg);
  transition: 0.3s ease;
}
.btn-anchor--blank:after {
  content: url(../img/common/ic_blank_bl.svg);
  display: inline-block;
  padding-left: 5px;
}
.btn-anchor:hover .arrow {
  background: #fff;
}
.btn-anchor:hover .arrow:after {
  border-color: #B40707;
}
@media (max-width: 1024px) {
  .btn-anchor {
    font-size: 1.3rem;
    line-height: 1.4615384615;
  }
}

/*　ボタン　ここまで　*/
/*　見出し　ここから　*/
.ttl-img {
  font-size: 3rem;
  font-weight: bold;
  font-family: "Montserrat", "Noto Sans JP", sans-serif;
  letter-spacing: 0.03em;
  text-align: center;
  background: url(../img/common/ttl_img.svg) center top no-repeat;
  padding: 50px 0 20px;
  position: relative;
}
.ttl-img:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: calc(50% - 15px);
  width: 30px;
  height: 2px;
  background: #D8D8D8;
}
@media (max-width: 1024px) {
  .ttl-img {
    font-size: 2.4rem;
    line-height: 1.5;
    padding: 47px 0 12px;
  }
}

.under-ttl {
  width: 1080px;
  max-width: 90%;
  margin: 0 auto;
}
.under-ttl .en {
  font-family: "Montserrat", "Noto Sans JP", sans-serif;
  font-size: 6rem;
  font-weight: bold;
  letter-spacing: 0.03em;
}
.under-ttl .jp {
  font-size: 1.3rem;
  letter-spacing: 0.1em;
  display: block;
  margin-top: 10px;
}
@media (max-width: 1024px) {
  .under-ttl .en {
    font-size: 4.5rem;
  }
}
@media (max-width: 599px) {
  .under-ttl {
    width: 315px;
  }
}

.ttl-border {
  color: #fff;
  font-size: 2.4rem;
  letter-spacing: 0.1em;
  font-weight: 500;
  line-height: 1.5;
  background: #101010;
  margin-bottom: 15px;
  border-left: 10px solid #B40707;
  padding: 7px 10px 7px 15px;
}
@media (max-width: 1024px) {
  .ttl-border {
    font-size: 1.8rem;
  }
}

/*　見出し　ここまで　*/
.news__list,
.schedule__list {
  display: flex;
  flex-wrap: wrap;
}
.news__link,
.schedule__link {
  display: block;
}
.news__link:hover,
.schedule__link:hover {
  text-decoration: underline;
}
.news__link:hover .img-wrap,
.schedule__link:hover .img-wrap {
  opacity: 0.7;
}
@media (min-width: 1025px) {
  .news__item,
  .schedule__item {
    width: calc((100% - 6px) / 3);
    margin-right: 3px;
  }
  .news__item:nth-of-type(3n),
  .schedule__item:nth-of-type(3n) {
    margin-right: 0;
  }
  .news__item:nth-of-type(n+4),
  .schedule__item:nth-of-type(n+4) {
    margin-top: 39px;
  }
}
@media (max-width: 1024px) {
  .news__item,
  .schedule__item {
    width: calc((100% - 1px) / 2);
    margin-right: 1px;
  }
  .news__item:nth-of-type(even),
  .schedule__item:nth-of-type(even) {
    margin-right: 0;
  }
  .news__item:nth-of-type(n+3),
  .schedule__item:nth-of-type(n+3) {
    margin-top: 42px;
  }
}
.news__item .img-wrap,
.schedule__item .img-wrap {
  background: center center/cover no-repeat;
  padding-bottom: 57%;
  transition: 0.3s ease;
  margin-bottom: 16px;
}
@media (max-width: 1024px) {
  .news__item .img-wrap,
  .schedule__item .img-wrap {
    padding-bottom: 100%;
    margin-bottom: 8px;
  }
}
.news__item .txt-wrap,
.schedule__item .txt-wrap {
  color: #101010;
}
.news__item .txt-wrap .ttl,
.schedule__item .txt-wrap .ttl {
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  line-height: 1.5714285714;
}
@media (max-width: 1024px) {
  .news__item .txt-wrap,
  .schedule__item .txt-wrap {
    padding: 0 7%;
  }
  .news__item .txt-wrap .ttl,
  .schedule__item .txt-wrap .ttl {
    font-size: 1.2rem;
    line-height: 1.5;
  }
}
.news .date,
.schedule .date {
  font-family: "Montserrat", "Noto Sans JP", sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
}
.news .cat-row,
.schedule .cat-row {
  margin: 0 -5px 6px;
}
.news .cat,
.schedule .cat {
  display: inline-block;
  margin: 0 5px 5px;
}
.news .cat__link,
.schedule .cat__link {
  font-size: 1.2rem;
  letter-spacing: 0.1em;
  color: #101010;
  font-weight: 500;
  border: solid 1px #101010;
  padding: 4px 7px;
  display: block;
  transition: 0.3s ease;
}
.news .cat__link:hover,
.schedule .cat__link:hover {
  background: #101010;
  color: #fff;
}
.news .cat__txt,
.schedule .cat__txt {
  font-size: 1.2rem;
  letter-spacing: 0.1em;
  color: #101010;
  font-weight: 500;
  border: solid 1px #101010;
  padding: 4px 7px;
  display: block;
  transition: 0.3s ease;
}
.news .cat--event .cat__txt:after,
.schedule .cat--event .cat__txt:after {
  display: block;
}
.news .cat--event .cat__txt.theater:after,
.schedule .cat--event .cat__txt.theater:after {
  content: "劇場公演";
}
.news .cat--event .cat__txt.kamenjoshi_gaibu:after,
.schedule .cat--event .cat__txt.kamenjoshi_gaibu:after {
  content: "仮面女子外部";
}
.news .cat--event .cat__txt.steamgirls_gaibu:after,
.schedule .cat--event .cat__txt.steamgirls_gaibu:after {
  content: "スチームガールズ外部";
}
.news .cat--event .cat__txt.photo_session:after,
.schedule .cat--event .cat__txt.photo_session:after {
  content: "撮影会";
}
.news .cat--event .cat__txt.media:after,
.schedule .cat--event .cat__txt.media:after {
  content: "メディア";
}
.news .cat--event .cat__txt.other:after,
.schedule .cat--event .cat__txt.other:after {
  content: "その他";
}
@media (max-width: 1024px) {
  .news .date,
  .schedule .date {
    margin-bottom: 5px;
  }
  .news .cat-row,
  .schedule .cat-row {
    margin-bottom: 0;
  }
  .news .cat,
  .schedule .cat {
    font-size: 1rem;
  }
}

.pagination {
  width: 960px;
  max-width: 90%;
  margin: 102px auto 0;
}
@media (max-width: 1024px) {
  .pagination {
    margin-top: 63px;
  }
}
@media (max-width: 599px) {
  .pagination {
    width: 315px;
  }
}
.pagination ul {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 0 35px;
}
.pagination ul .page-numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  font-family: "Montserrat", "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  color: #101010;
  transition: 0.3s ease;
  width: 32px;
  height: 32px;
}
.pagination ul .page-numbers:not(.prev, .next) {
  margin: 0 9px;
}
.pagination ul .page-numbers:not(.prev, .next).current, .pagination ul .page-numbers:not(.prev, .next):hover {
  position: relative;
}
.pagination ul .page-numbers:not(.prev, .next).current:after, .pagination ul .page-numbers:not(.prev, .next):hover:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  width: 20px;
  height: 2px;
  background: #101010;
}
@media (max-width: 1024px) {
  .pagination ul .page-numbers {
    width: 30px;
    height: 30px;
  }
  .pagination ul .page-numbers:not(.prev, .next) {
    margin: 0 5px;
  }
}
.pagination ul .page-numbers.prev, .pagination ul .page-numbers.next {
  border: solid 1px #D8D8D8;
  position: absolute;
}
.pagination ul .page-numbers.prev:after, .pagination ul .page-numbers.next:after {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: solid 2px #101010;
  border-right: solid 2px #101010;
}
.pagination ul .page-numbers.prev:hover, .pagination ul .page-numbers.next:hover {
  background: #D8D8D8;
}
.pagination ul .page-numbers.prev:hover:after, .pagination ul .page-numbers.next:hover:after {
  border-color: #fff;
}
.pagination ul .page-numbers.prev {
  left: calc(50% - 190px);
}
.pagination ul .page-numbers.prev:after {
  transform: rotate(-135deg);
  left: calc(50% - 3px);
}
@media (max-width: 1024px) {
  .pagination ul .page-numbers.prev {
    left: 0;
  }
}
.pagination ul .page-numbers.next {
  right: calc(50% - 190px);
}
.pagination ul .page-numbers.next:after {
  transform: rotate(45deg);
  right: calc(50% - 3px);
}
@media (max-width: 1024px) {
  .pagination ul .page-numbers.next {
    right: 0;
  }
}

.header {
  background: #101010;
  text-align: center;
}
@media (min-width: 1025px) {
  .header {
    padding: 60px 0 5px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 15%;
    max-width: 240px;
    min-width: 170px;
    height: 100vh;
    overflow: auto;
  }
}
@media (max-width: 1024px) {
  .header {
    padding: 17px calc(5% + 50px) 16px 5%;
  }
}
.header__logo-wrap {
  margin-bottom: 30px;
}
@media (max-width: 1024px) {
  .header__logo-wrap {
    margin: 0;
  }
}
.header__nav {
  display: inline-block;
  width: auto;
  min-width: 110px;
  text-align: left;
}
.header__nav .menu__item:nth-of-type(n+2) {
  margin-top: 22px;
}
.header__nav .menu__link {
  font-size: 1.3rem;
  color: #fff;
  font-family: "Montserrat", "Noto Sans JP", sans-serif;
  font-weight: 500;
  letter-spacing: 0.03em;
  transition: 0.3s ease;
  padding-bottom: 7px;
  border-bottom: 1px solid transparent;
}
.header__nav .menu__link:hover {
  border-color: #fff;
}
.header__nav .menu__link--blank:after {
  content: url(../img/common/ic_blank.svg);
  display: inline-block;
  padding-left: 5px;
}
@media (max-width: 1024px) {
  .header__nav {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    height: 100vh;
    background: #101010;
    padding: 17px 5% 40px;
    overflow: auto;
  }
  .header__nav .logo-wrap {
    text-align: center;
    margin-bottom: 31px;
    padding-right: 50px;
  }
  .header__nav .menu {
    margin: 0 auto;
    width: 214px;
    max-width: 100%;
  }
  .header__nav .menu__item:nth-of-type(n+2) {
    margin-top: 20px;
  }
  .header__nav .menu__link {
    padding: 0;
    border: none;
  }
}
.header .menu-trigger {
  position: fixed;
  display: block;
  right: 5%;
  top: 16px;
  z-index: 999;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.header .menu-trigger span {
  display: inline-block;
  height: 3px;
  background: #101010;
  position: absolute;
  left: 10px;
}
.header .menu-trigger span:nth-of-type(1) {
  width: 26px;
  top: 30%;
}
.header .menu-trigger span:nth-of-type(2) {
  width: 20px;
  top: 50%;
}
.header .menu-trigger span:nth-of-type(3) {
  width: 14px;
  top: 70%;
}
.header .menu-trigger.active {
  width: 40px;
  height: 40px;
  background: none;
  border-radius: 0;
  box-shadow: none;
}
.header .menu-trigger.active span {
  background: #fff;
  width: 100%;
  height: 3px;
  left: 50%;
}
.header .menu-trigger.active span:nth-of-type(1) {
  top: 50%;
  transform: translateX(-50%) rotate(36deg);
}
.header .menu-trigger.active span:nth-of-type(2) {
  opacity: 0;
}
.header .menu-trigger.active span:nth-of-type(3) {
  top: 50%;
  transform: translateX(-50%) rotate(-36deg);
}

@media (min-width: 1025px) {
  .main {
    margin-top: 93px;
    display: block;
    width: 85%;
    max-width: calc(100% - 170px);
    min-width: calc(100% - 240px);
    margin-left: auto;
  }
}
@media (max-width: 1024px) {
  .main {
    margin-top: 46px;
  }
}

.footer {
  margin-top: auto;
  background: #2A2A2A;
  padding: 39px 0 32px;
  position: relative;
}
@media (min-width: 1025px) {
  .footer {
    width: 85%;
    max-width: calc(100% - 170px);
    min-width: calc(100% - 240px);
    margin-left: auto;
  }
}
@media (max-width: 1024px) {
  .footer {
    padding: 41px 5% 42px;
  }
}
.footer__logo-wrap {
  text-align: center;
}
.footer__nav {
  margin: 42px 0 35px;
}
.footer__nav .menu {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.footer__nav .menu__item {
  margin: 0 9px 5px;
}
.footer__nav .menu__link {
  color: #fff;
  font-family: "Montserrat", "Noto Sans JP", sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 1px solid transparent;
  transition: 0.3s ease;
}
.footer__nav .menu__link:hover {
  border-color: #fff;
}
.footer__nav .menu__link--blank:after {
  content: url(../img/common/ic_blank.svg);
  display: inline-block;
  padding-left: 5px;
}
@media (max-width: 1024px) {
  .footer__nav {
    max-width: 100%;
    width: 315px;
    margin: 40px auto 30px;
  }
  .footer__nav .menu__item {
    margin: 0 6px 12px;
  }
}
.footer .sns {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
.footer .sns__link {
  margin: 0 8px;
}
.footer__nav2 {
  margin-bottom: 83px;
}
.footer__nav2 .menu {
  display: flex;
  justify-content: center;
}
.footer__nav2 .menu__item {
  padding: 0 20px 2px;
}
.footer__nav2 .menu__item:nth-of-type(n+2) {
  border-left: solid 1px #fff;
}
.footer__nav2 .menu__link {
  color: #fff;
  font-size: 1.1rem;
  letter-spacing: 0.05em;
}
.footer .copyright {
  color: #fff;
  font-size: 1rem;
  letter-spacing: 0.1em;
  text-align: center;
}